


















































































































































































































































































































@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.reportWrapper > hr {
  margin: 3rem 0;
}

.tabs > :first-child {
  margin-bottom: 3rem;
}

.tab-content .tab-pane {
  &.active:first-child ~ .tab-pane {
    display: block !important;
  }

  &.active, &:last-of-type {
    > .reportWrapper > hr {
      display: none;
    }
  }
}

